.row {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.col {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/*.flex {*/
/*    display: flex;*/
/*    flex: 1;*/
/*    height: 100%;*/
/*}*/
